.Backdrop {

	width: 230vw;
	height: 230vh;
	position: fixed;
	z-index: 300;
	left: 0;
	top: 0;
	background-color: rgba(20,20,112,0.8);

}

.opaqueBackdrop {
	background-color: rgba(20,20,112,1);
	z-index: 501;
}