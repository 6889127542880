.musicMenuUnlockedSongRow {
	display: flex;
	padding-bottom: 4px;
	justify-content: space-between;
	width: 600px;
	align-items: baseline;
	
}

.songLoadButton {
	width: 100px;
	position: relative;
	margin-right: 40px;
	top: 8px;
}

.songLoadButton:hover {
	opacity: 1;
}

.musicMenuUnlockedSongRowDescription {
	margin-left: 50px;
  	font-size: 0.8em;
}

.musicMenuUnlockedSongRowDescriptionHoistUp {
	margin-top: -15px;
	padding-bottom: 15px;
}