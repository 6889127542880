.songDescription {
	margin-left: 50px;
  	font-size: 0.8em;
	margin-top: -15px;
	padding-bottom: 15px;
	transition: color 0.3s;
}

.songRemovedFromPlaylist {
	opacity: 0.4;
}
