.researchButtonMenu {
  padding: 20px;
  overflow: hidden;
  opacity: 0;
  margin: 0;
  transition: opacity 0s ease 0s;
  max-height: 0px;
}

.researchButtonMenu.open {
  height: auto;
  justify-content: space-evenly;
  flex-direction: column;
  color: black;
  overflow: none;
  display: flex;
  opacity: 1;
  transition: opacity 0.2s ease 0.3s, max-height 2s ease 0.3ms;
  max-height: 600px;
  margin-right: 30px;
  }

  .noIdeas {
    width: 540px;
    font-size: 1.2em;
    line-height: 0.1em;
    text-align: left;
  }