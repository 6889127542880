#inventoryBox {
  display: flex;
  margin: 0px 30px;
  padding: 10px;
  align-items: center;                           
  flex: 3;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: var(--light-border);
  border-radius: 2vh;
  height: 60%;
  box-shadow: inset 1px 1px 6px var(--interior-shading);
  justify-content: space-evenly;
  
}