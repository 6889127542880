
#buttonBox {
  display: flex;
  justify-content: center;
  padding-left: 1vw;
  margin-bottom: 10px;
}

#collect {
  position: relative;
  height: 8vh;
  width: 12vw;
  padding: 10px 25px;
  font-size: 2.1vh;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: var(--darker-elements);
  border: none;
  border-radius: 2vh;
  box-shadow: 0 9px #888;
  font-family: 'nunito';
  font-weight: 700;
  overflow: hidden;
}

@media screen and (max-height: 760px)  {
    #collect {
      height: 70px;
      padding: 15px 0;
      font-size: 18px;
    }
}

@media screen and (max-height: 650px)  {
    #collect {
      height: 50px;
      padding: 0px 0;
      font-size: 16px;
    }
}

@media screen and (max-width: 1500px)  {
    #collect {
      max-width: 200px;
    }
}


#collect:active {
  background-color: var(--darker-elements);
  box-shadow: 0 5px #555;
  transform: translateY(4px);
    -webkit-appearance: none;
  -moz-appearance:    none;
  appearance:         none;
    outline: 0 none;
  outline-style: none;
}

#collect:focus {
  outline: 0 none;
  outline-style: none;
  -webkit-appearance: none;
  -moz-appearance:    none;
  appearance:         none;
}

#collect:disabled {
  background-color: var(--darker-elements);
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

#collect:before {
   content: "";
   width: 400%;
   height: 600%;
   position: absolute;
   top: -600%;
   left: -425%;

   background-image: -webkit-linear-gradient(135deg, rgba(255,255,255,0), rgba(255,255,255,0.5), rgba(255,255,255,0)); 
   background-image: -moz-linear-gradient(135deg, rgba(255,255,255,0), rgba(255,255,255,0.5), rgba(255,255,255,0)); 
   background-image: linear-gradient(135deg, rgba(255,255,255,0), rgba(255,255,255,0.5), rgba(255,255,255,0));  
    
     -moz-transition: all 0.8s ease-out; 
       -o-transition: all 0.8s ease-out; 
  -webkit-transition: all 0.8s ease-out;
      -ms-transition: all 0.8s ease-out;
      transition: all 0.8s ease-out;
}

#collect:disabled:before {
  top: 400%;
  left: 300%;  
}
