header {
  display: flex;
  background-color: var(--main-background);
  position: fixed;
  top: 0;
  right: 0;
  width: 99vw;
  padding-top: 2.2vh;
  padding-bottom: 2.8vh;
  padding-left: 2vw;
  padding-right: 0.8vw;
  border: 0px;
  border-bottom: 1px solid var(--deep-purple);
  border-left: 1px solid var(--deep-purple);
  border-radius: 0 0 0 30px;
  z-index: 2;
  -webkit-transition: transform 1.6s ease-out;
  transition:         transform 1.6s ease-out;
  box-shadow: 0 11px 16px -3px rgba(45,35,66,0.3), 0 4px 4px 0 rgba(45,35,66,0.4);

}

.headerBox {
  display: flex;
  align-items: center;
  background-color: var(--box-background);
  border-radius: 3vh;
  height: 12.8vh;
  border-top: 0;
}

#headerBoxTitle {
  margin-left: 2.5vw;
  margin-right: 1vw;
  width: 22vw;
  position: relative;
}

#headerBoxTimer {
  margin: 0 1vw;
  width: 46vw;
  padding: 0 0.5vw 0 1vw;
}

#headerBoxInventory {
  margin: 0 1vw;
  width: 40vw;
  min-width: 365px;
  top: 2vh;
}

header.hiddenHeader {
  transform: translate(150vw, 0);
}