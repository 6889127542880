/*redtext, greentext, purchase button css is in ResearchMenuRow.css*/

.musicMenuLockedSongRow {
	border-radius: 10px;
	border: 3px solid var(--darker-elements);
	padding: 10px 0px;
	margin: 26px 60px;
}

.musicMenuLockedSongRowTopLine {
	display: flex;
	margin-left: -20px;
	padding-bottom: 4px;
	justify-content: space-between;
	width: 770px;
	align-items: bottom;
}

.songPurchaseButton {
	width: 130px;
	position: relative;
	top: 28px;
	height: 60px;
}

.musicInspirationReqs {
	margin-top: -15px;
	margin-left: 30px;
	margin-bottom: 6px;
	display: grid;
	width: 600px;
	grid-template-columns: 120px 140px 140px 140px;
  	grid-template-rows: auto;
  	font-size: 0.8em;
}

.musicInspirationReqs div {
	text-align: right;
}

.tinyCellPadding {
	padding: 7px 0px;
}

.musicCellLowercase {
	text-transform: lowercase;
}