#timerBox {
  background-color: var(--light-border);
  width: 26vw;
  box-shadow: inset 1px 1px 6px var(--interior-shading);
  position: relative;
  border-radius: 5px;
  height: 4.3vh;
  align-items: center;
  margin: 2.4vh 1.7vw 2.3vh 2vw;

}

#timerBar {
  --one-time-unit: 60ms;
  /* width: 1px; */
  height: 3.5vh;
  background-color: var(--darker-elements);
  margin: 0.4vh 0px;
  transition: width var(--one-time-unit) linear; 
}