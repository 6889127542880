.ModalBox {
	position: fixed;
	z-index: 500;
	width: 50vw;
	max-width: 1000px;
	max-height: 74vh;

	box-sizing: border-box;
	transition: all 0.3s ease-out;

	background: var(--light-purple);
	border: 5px solid var(--deep-purple);
	border-radius: 26px;

	padding: 20px 30px;
	text-align: center;

	font-family: quicksand;
 	box-shadow: 4px 4px 12px black;
}