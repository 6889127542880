/* Insert media breakpoint: width: 1200px;
*/

/* Perhaps use this color theme:
https://color.adobe.com/create/color-wheel/?base=2&rule=Analogous&selected=2&name=My%20Color%20Theme&mode=rgb&rgbvalues=0.2749311294769843,0.21666666666666656,1,0.19716666666666657,0.33359409888325225,0.91,0.26666666666666666,0.6,1,0.19716666666666657,0.7087695374801188,0.91,0.21666666666666656,0.9850079744813911,1&swatchOrder=0,1,2,3,4
*/

:root {

    --main-background: #49f;
    --header-background: #004c36;
    --header-border: #191919;
    --header-inside-border: #191919;
    --darker-elements: #001166;
    

    --deep-purple: #1f0d59;
    --light-purple: #cac3da;
    
    --box-border: #001166;
    --box-background: #cbd6f1;
    --light-border: #b6c9ed;
    --focus-input: #c0d3f9;

    --title-border: #cfe8f0;
    --title-text: var(--darker-elements);

    --hover-research-btn: #74adb1;

    --hint-background: #a6edc7;
    --hint-border: #004c36;

    --interior-shading: #104e8b;

    /*replacement tests*/
    --darker-elements: #1f0d59;
    --box-border: #1f0d59;
}

::-webkit-scrollbar {
    display:none;
}




@font-face {
  font-family: 'MegalopolisExtra-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('MegalopolisExtra-Regular'), 
       url('../fonts/MegalopolisExtra-Regular.woff') format('woff'),
       url('../fonts/MegalopolisExtra-Regular.otf') format('opentype'),
       url('../fonts/MegalopolisExtra-Regular.eot') format('eot');
} 

button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
    outline:0px !important;
    -webkit-appearance:none;
}


body {
  background-color: var(--main-background);
  font-family: 'nunito';
  font-weight: 400;
  margin: 0;
}

footer {
  position: fixed;
  display: block;
  background-color: var(--header-background);
  bottom: 0;
  padding-top: 20px;
  left: 60px;
  right: 0;
  border-top: 5px solid var(--header-border);
  border-left: 5px solid var(--header-border);
  border-radius: 40px 0 0 0;
  z-index: 0;
}

h1 {
  text-align: center;
  font-family: 'MegalopolisExtra-Regular'; 
}

p {
  font-size: 16px;
  padding: 10px;
}

em {
  font-size: 0.8em;
}