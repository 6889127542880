.discoveryList {
  list-style-type: none;
  font-size: 16px;
  margin-top: -12px;
  display: inline;
  padding-left: 1.2em; 
  font-family: quicksand;
  font-variant: small-caps;

}

.listItem {
  float: left;
  padding-right: 8px;
  padding-left: 4px; 
}


.discListDot {
  float: left;
  padding-right: 8px;
  padding-left: 4px; 
  color: var(--main-background);
}
