.discoveriesContainer {
  position: absolute;
  top: 18.4vh;
  width: 89vw;
  margin-left: -1vw;
  padding: 20px;
  padding-left: 6vw;
  padding-right: 30px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: left;
  z-index: -2;
  padding-bottom: 20vh;
}



/* at 1800 make left margin smaller*/

@media screen and (max-width: 1820px)  {
  .discoveriesContainer {
    margin-left: -3vw;
    padding-right: 20px;
    width: 92vw;
  }
}

@media screen and (max-width: 1700px)  {
  .discoveriesContainer {
    margin-left: 6vw;
    width: 85vw;
  }
}

@media screen and (max-width: 1400px)  {
  .discoveriesContainer {
    margin-left: 2vw;
    width: 89vw;
  }
}