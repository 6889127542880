.resetModalButton {
  position: relative;
  height: auto;
  width: 140px;
  padding: 10px 30px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: var(--darker-elements);
  border: none;
  border-radius: 20px;
  box-shadow: 0 7px #888;
  font-family: 'nunito';
  font-weight: 700;
  overflow: hidden;
  margin: 10px 20px;
}

.resetModalButton:active {
  background-color: var(--darker-elements);
  box-shadow: 0 3px #555;
  transform: translateY(4px);
  -webkit-appearance: none;
  -moz-appearance:    none;
  appearance:         none;
  outline: 0 none;
  outline-style: none;
}