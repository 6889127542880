.App {


}

.bush {
	position: fixed;
	bottom: -40px;
	right: 200px;
	height: 250px;
	transition: 5s ease-in-out;
}

.bushhidden {
	bottom: -220px;
	transition: 1s ease-in-out;
}


@keyframes bushdance {
  from {
    transform: skew(2deg);
  }
  
  15%,
  25%,
  40%,
  50% {
    transform: skew(-2deg);
  }

  20%,
  21%,
  45%,
  46%
 {
    transform: scale(1, 1.02) skew(-3deg); 
  }

  65%,
  75%,
  90%,
  100% {
    transform: skew(2deg);
  }

  70%,
  71%,
  95%,
  96% {
    transform: scale(1, 1.02) skew(3deg); 
  }
}


.bushdance {
  --bpm: 98;
  --rockspeed: 480s;
  animation: bushdance calc(var(--rockspeed)*1s/var(--bpm)) infinite;
  transform-origin: 50% 100%;
}