.discoveryBox {
  display: flex;
	padding: 20px;
  padding-right: 40px;
  padding-left: 28px;
  flex-direction: column;
  width: 23vw;
	min-width: 400px;
  min-height: 30vh;
	background-color: var(--box-background);
	border: 0px solid var(--box-border);
	margin: 20px;
	border-radius: 20px;
	box-shadow: 0 11px 16px -3px rgba(45,35,66,0.3), 0 4px 5px 0 rgba(45,35,66,0.4);
	z-index: 0;
}

.discoveryBoxDoubleSized {
  width: calc(46vw + 108px);
  min-width: 908px;
}

@media screen and (max-width: 1700px)  {
  .discoveryBox {
    width: 29vw;
  }
  /* .discoveryBoxDetails {
    width: 29vw;
  }*/
  .discoveryBoxDoubleSized {
    width: calc(58vw + 108px);
  }
}

@media screen and (max-width: 1400px)  {
  .discoveriesContainer {
    margin-left: 2vw;
    width: 89vw;
  }
}

.invisibleDisc {
  opacity: 0;
}
