.settingsButtonMenu {
  padding: 10px 40px;
  overflow: hidden;
  opacity: 0;
  margin: 0;
  transition: opacity 0s ease 0s;
  max-height: 0px;
}

.settingsButtonMenu.open {
  height: auto;
  justify-content: space-evenly;
  flex-direction: column;
  color: black;
  overflow: none;
  display: flex;
  opacity: 1;
  transition: opacity 0.2s ease 0.3s, max-height 1.5s ease 0.3ms;
  max-height: 800px;
  margin: 0;
  margin-right: 30px;
  }

.settingsButtonMenu h2 {
  font-family: 'MegalopolisExtra-Regular';
  margin-top: 20px;
  margin-bottom: 10px;
  color: var(--darker-elements);
}

.settingsButtonMenu p {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 30px;
}

.settingsButtonMenu ul {
  margin-top: 0;
  margin-bottom: 0;
}

.settingsButtonMenu a {
  text-decoration: none;
}

.settingsButtonMenu button {
  position: relative;
  height: auto;
  width: 140px;
  padding: 10px 30px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: var(--darker-elements);
  border: none;
  border-radius: 20px;
  box-shadow: 0 7px #888;
  font-family: 'nunito';
  font-weight: 700;
  overflow: hidden;
  margin: 0 20px 10px 20px;
}

.settingsButtonMenu button:active {
  background-color: var(--darker-elements);
  box-shadow: 0 3px #555;
  transform: translateY(4px);
  -webkit-appearance: none;
  -moz-appearance:    none;
  appearance:         none;
  outline: 0 none;
  outline-style: none;
}

.saveButtonsBox {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  padding-left: 20px;
}