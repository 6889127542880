.console {
  position: fixed;
  background-color: var(--header-background);
  border-left: 5px solid var(--header-border);
  right: 0;
  top: 0;
  bottom: 20px;
  display: flex;
  width: 82px;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  z-index: 1;
  transition: transform 0.7s ease-out;
}

.consolehidden {
  transform: translate(106px, 0);
}

.consoleshown {
  transform: translate(0, 0);
}