.discoveryFail {
  color: black; 
  background-color: salmon;
  border-radius: 10px;
  padding: 0.8em 0.8vw;
  text-align: center;
  max-width: 130px;
  position: absolute;
  opacity: 0;

  -webkit-animation-name: shake;
  animation-name: shake;
  animation-duration: 1.3s;
}


.discoverySuccess {
  color: black; 
  background-color: #66cdaa;
  border-radius: 10px;
  padding: 0.8em 0.8vw;
  text-align: center;
  max-width: 130px;
  position: absolute;
  opacity: 0;


  -webkit-animation-name: rising;
  animation-name: rising;
  animation-duration: 2.2s;
}

@media screen and (min-width: 1490px)  {
  .discoveryFail {
    margin-left: 0.8vw;
  }
  .discoverySuccess {
    margin-left: 0.6vw;
  }
}