.suggBigField {
	padding: 16px;
	margin: 20px;
	margin-bottom: 0;
	border-radius: 12px;
	border: solid 1px var(--deep-purple);
	font-family: inherit;
	font-size: 0.93em;
}

.feedbackSubmitBtn {
	height: auto;
  width: 140px;
  padding: 10px 30px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: var(--darker-elements);
  border: none;
  border-radius: 12px;
  box-shadow: 0 7px #888;
  font-family: 'nunito';
  font-weight: 700;
  overflow: hidden;
  margin: 20px 20px 0px 20px;
}

.feedbackSubmitBtn:active {
  background-color: var(--darker-elements);
  box-shadow: 0 3px #555;
  transform: translateY(4px);
  -webkit-appearance: none;
  -moz-appearance:    none;
  appearance:         none;
  outline: 0 none;
  outline-style: none;
}

#feedbackScrollBox {
	overflow-y: auto;
	height: 68vh;
}

#feedbackScrollBox:after {
	content: "";
	display: block;
	height: 20px;
	width: 100%;
}
