.musicMenuPlayer {
  margin-left: auto;
  margin-right: auto;
  width: 620px;
  border: 3px solid var(--darker-elements);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 60px;
}

.musicMenuPlayerFlexContainer {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	width: 600px;
}

.musicPlayerButton {
  height: 46px;
  width: 46px;
  position: relative;
  background-color: var(--darker-elements);
  border: 3px solid var(--deep-purple);
  cursor: pointer;
  border-radius: 15px;
}

.musicPlayerButtonHidden {
	visibility: hidden;
}


.musicPlayerButton:hover {
  background-color: var(--header-background);
}

.musicPlayerIcon {
  font-size: 20px;
  color: white;
 }

 .musicMenuPlayerButtons {
 	position: relative;
 	top: 10px;
	display: grid;
	width: 160px;
	margin-right: 30px;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: auto;
 }
