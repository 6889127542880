
.tutorialBoxText {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  line-height: 0.6em;
  padding: 30px 80px;
  opacity: 1;
}


.hiddenTutorialIntroText h3 {
  opacity: 0;
  transition-delay: 0s;
  transition-duration: 0s;
}

.hiddenTutorialIntroText button {
  opacity: 0;
  transition-delay: 0s;
  transition-duration: 0s;
}

.delay1 {
  transition-delay: 0.2s;
  transition-duration: 0.8s;
}

.delay2 {
  transition-delay: 2s;
  transition-duration: 0.8s;
}

.delay3 {
  transition-delay: 3.5s;
  transition-duration: 0.8s;
}

.delay4 {
  transition-delay: 5s;
  transition-duration: 0.8s;
}

.delay5 {
  transition-delay: 6.5s;
  transition-duration: 0.8s;
}


.startGameBtn {
  opacity: 0;
  position: relative;
  height: 75px;
  width: 250px;
  padding: 15px 40px;
  font-size: 20px;
  text-align: center;
  outline: none;
  color: #fff;
  background-color: var(--darker-elements);
  border: none;
  font-family: 'nunito';
  border-radius: 20px;
  font-weight: 700;
  margin: 30px 0 50px 0;
  box-shadow: 0 9px #888;
}

.startGameBtn:active {
  background-color: var(--darker-elements);
  box-shadow: 0 5px #555;
  transform: translateY(4px);
}

.showStartBtn {
  opacity: 1;
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  animation-duration: 0.6s;
  cursor: pointer;
}

.tutorialFadeout {
  opacity: 0;
  transition-duration: 0.4s;
  transition-delay: 0;
}

.tutorialBoxStage1 button {
  opacity: 1;
}

.tutorialBoxStage1 .hideStartBtn {
  opacity: 0;
}