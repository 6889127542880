
.inputComponent {
  display: flex;
  height: 3em;
  justify-content: left;
  align-items: center;
}

.discoveryInput {
  width: 46%;
  min-width: 200px;
  padding: 15px 2px 15px 12px;
  margin-right: 1.5em;
  margin-top: -1px;
  margin-left: 10px;
  border-style: none;
  font-family: nunito;
  background-color: var(--light-border);
  box-shadow: inset 1px 1px 4px var(--interior-shading);
  border-radius: 8px;
  transition: 0.4s;
}

.discoveryInput:disabled {
  background: white;
  border-style: solid;
  border-color: var(--light-border);
  padding: 6px; 
}

.discoveryInput:focus {
  background: var(--focus-input);
  outline: none;
}

.discoveryButton {
  height: 2.6em;
  width: 2.6em;
  color: white;
  border-radius: 6px;
  background-color: var(--darker-elements);
  border-color: var(--darker-elements);
  outline: none;
  box-shadow: 0 3px #888;
  transform: translateY(-3px);
}

.discoveryButton:active {
  box-shadow: 0 1px #555;
  transform: translateY(-1px);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0 none;
  outline-style: none;
}

.discOutcomeBox {
  display: relative; 
  width: 50%;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
}