.titleBox {
  width: 17vw;
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#title {
  color: var(--title-text);
  text-shadow: 2px 3px 0px rgba(0,0,200,0.2);
  letter-spacing: -0.75px; 
  line-height: 1.2;
  font-size: 1.8vw;
  width: 16.5vw;
  padding: 0 0.25vw;
}


