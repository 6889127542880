.consoleButton {
  position: absolute;
  right: 11px;
  width: 50px;
  max-width: 50px;
  min-height: 50px;
  overflow: visible;
  background: var(--deep-purple);
  border: 5px solid var(--header-border);
  border-radius: 20px;
  outline: none;
  transition: max-width 2s ease, background-color 0.3s ease 0.3s;
  cursor: pointer;
  z-index: 8;
}

.researchConsoleButton {
  top: 22vh;
}

.settingsConsoleButton {
  bottom: 25px;
  opacity: 0;
  visibility: hidden;
}

.musicConsoleButton {
  bottom: 100px;
  opacity: 0;
  visibility: hidden;
}

.showConsoleButton{
  visibility: visible;
  opacity: 1;
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  animation-duration: 0.6s;
}

.consoleButton.openConsoleMenu {
  background: var(--light-purple);
  border: 5px solid var(--deep-purple);
  width: auto;
  max-width: 900px;
  min-width: 50px;
  transition: max-width 1s ease 0s, background-color 0.3s;
}

.consoleIcon {
  position: absolute;
  right: 2px;
  padding: 10px;
  top: 0px;
  font-size: 30px;
  color: var(--box-background);
  z-index: 12;
  transition: transform 0.3s ease, color 0.3s ease 0.4s,
}

.settingsIconAlign {
  right: 0;
  top: auto;
  bottom: 0;
}

.guitarIcon{
  transform: rotate(-45deg);
}

.consoleIcon.openConsoleMenu{
  transform: rotate(-90deg);
  color: var(--deep-purple);
  transition: transform 0.5s ease, color 0.3s ease,
}

