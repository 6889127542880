.discTitleBar {
  background: var(--box-border);
  position: relative;
  border-radius: 12px 12px 0 0;
  width: calc(100% + 69px);
  min-width: 470px;
  color: white;
  padding: 0px;
  margin-left: -24px;
  margin-top: -14px;
  margin-bottom: 20px;
  left: -5px;
  top: -8px;
  display: flex;
  justify-content: center;
  font-size: 1.2em;
}

.discIcon {
  position: absolute;
  left: 30px;
}


@keyframes bigDiscTitle {
  from,
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    color: white;
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  57.5%,
  72.5% {
    -webkit-transform: scale(1);
    transform: scale(1);
    color: tomato;
  }

  50%,
  65%,
  80% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    color: tomato;
  }
}

@-webkit-keyframes bigDiscTitle {
  from,
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    color: white;
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  57.5%,
  72.5% {
    -webkit-transform: scale(1);
    transform: scale(1);
    color: tomato;
  }

  50%,
  65%,
  80% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    color: tomato;
  }
}


.animateTitle {
  -webkit-animation-name: bigDiscTitle;
  animation-name: bigDiscTitle;
  animation-duration: 2.8s;
  transform-origin: 8% 50%;
}