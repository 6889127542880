.musicPurchaseButton {
  background-color: var(--darker-elements);
  border: 3px solid var(--deep-purple);
  height: 46px;
  color: white;
  padding: 4px 20px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  width: 150px;
  border-radius: 15px;
  margin-right: 20px;
  margin-top: -2px;
} 

.musicPurchaseButton:hover {
  background-color: var(--header-background);
}

.musicPurchaseButton:disabled {
  background-color: #666;
}


.musicButtonMenu {
  opacity: 0;
  margin: 0;
  transition: opacity 0s ease 0s;
  max-height: 0px;
  width: 900px;
  overflow: hidden;
}

.musicButtonMenu.open {
  height: calc(70vh - 100px);
  color: black;
  opacity: 1;
  transition: opacity 0.2s ease 0.3s, max-height 1.5s ease 0.3ms;
  max-height: 800px;
  margin: 0;
  }

.musicButtonMenu h2 {
  font-family: 'MegalopolisExtra-Regular';
  margin-top: 40px;
  margin-bottom: 30px;
  color: var(--darker-elements);
  text-align: center;
}

.musicButtonMenu p {
  font-size: 20px;
  margin-top: 18px;
  margin-bottom: 1em;
  padding: 0 50px;
}

.musicButtonMenu ul {
  margin-top: 0;
  margin-bottom: 0;
}

.musicButtonMenu a {
  text-decoration: none;
}

.unlockedSongContainer {
  margin-left: auto;
  margin-right: auto;
  width: 620px;
  border: 3px solid var(--darker-elements);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 30px;
}

.musicNoteIcon {
  margin-left: 42px;
  margin-right: 48px;
}

@keyframes rockin {
  from {
    transform: rotate(-20deg);
  }
  50% {
    transform: rotate(30deg);
  }
  to {
    transform: rotate(30deg);
  }
}

.rockin {
  --bpm: 132;
  --rockspeed: 120s;
  transform-origin: 60% 50%;
  animation: rockin calc(var(--rockspeed)*1s/var(--bpm)) steps(1, end) infinite;
}

