.purchaseButton {
  background-color: var(--darker-elements);
  border: 3px solid var(--deep-purple);
  height: 46px;
  color: white;
  padding: 4px 20px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  width: 150px;
  border-radius: 15px;
  margin-right: 30px;
  margin-left: 30px;
  margin-top: 9px;
} 

.purchaseButton:hover {
  background-color: var(--header-background);
}

.purchaseButton:disabled {
  background-color: #666;
}

.upgradeRow {
  font-size: 20px;
  padding: 8px;
  width: 575px;
  position: relative;
  display: flex;
}

.upgradeText {
  display: block;
  float: right;
  width: 70%;
  white-space: pre-wrap;
  margin-top: 12px;
  padding-left: 20px;
}

.unselectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.redtext {
  color: #dc0000;
}

.greentext {
  color: var(--header-background);
}


.researchDescription {
  margin-left: 75px;
  font-size: 0.8em;
}
