.tutorialBox {
  position: fixed;
  background-color: var(--hint-background);
  border: 0px solid var(--hint-border);
  border-radius: 20px;
  box-shadow: 0 11px 16px -3px rgba(45,35,66,0.3), 0 4px 5px 0 rgba(45,35,66,0.4);
  color: var(--hint-border);
  z-index: 6;
}

.tutorialBoxStage1 {
  left: 20vw;
  bottom: 15vh;
  width: 60vw;
  max-width: 600px;
  max-height: 70%;
  padding-top: 30px;
  z-index: 0;
}

.smallFirstTutorialBox {
  overflow: hidden;
  max-height: 6em;
  transition: max-height 0.50s ease-in-out;
}


.tutorialBoxStage2 {
  left: 20%;
  top: 280px;
  max-width: 600px;
  transition-duration: 0s;
  transition-delay: 0s;
}

.tutorialBoxStage3 {
  left: 45%;
  top: 280px;
}

.tutorialBoxStage3:after, .tutorialBoxStage3:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.tutorialBoxStage3:after {
  border-color: rgba(166, 237, 199, 0);
  border-bottom-color: #a6edc7;
  border-width: 22px;
  margin-left: -22px;
}
.tutorialBoxStage3:before {
  border-color: rgba(0, 76, 54, 0);
  border-bottom-color: #004c36;
  border-width: 23px;
  margin-left: -23px;
}


.tutorialBoxStage4 {
  right: 8%;
  top: 280px;
}

.tutorialBoxStage4:after, .tutorialBoxStage4:before {
  bottom: 100%;
  left: 75%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.tutorialBoxStage4:after {
  border-color: rgba(166, 237, 199, 0);
  border-bottom-color: #a6edc7;
  border-width: 22px;
  margin-left: -22px;
}
.tutorialBoxStage4:before {
  border-color: rgba(0, 76, 54, 0);
  border-bottom-color: #004c36;
  border-width: 23px;
  margin-left: -23px;
}

.tutorialBoxStage5 {
  left: 740px;
  top: 300px;
}


.tutorialBoxStage5:after, .tutorialBoxStage5:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.tutorialBoxStage5:after {
  border-color: rgba(166, 237, 199, 0);
  border-right-color: #a6edc7;
  border-width: 32px;
  margin-top: -32px;
}
.tutorialBoxStage5:before {
  border-color: rgba(0, 76, 54, 0);
  border-right-color: #004c36;
  border-width: 33px;
  margin-top: -33px;
}

.tutorialBoxStage6 {
  left: 49%;
  top: 250px;
}

.tutorialBoxStage7 {
  left: 54%;
  top: 250px;
}

.tutorialBoxStage8 {
  right: 20px;
  top: 55%;
  z-index: 5;
}

.tutorialBoxStage8:after, .tutorialBoxStage8:before {
  bottom: 100%;
  left: 93%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.tutorialBoxStage8:after {
  border-color: rgba(166, 237, 199, 0);
  border-bottom-color: #a6edc7;
  border-width: 22px;
  margin-left: -22px;
}
.tutorialBoxStage8:before {
  border-color: rgba(0, 76, 54, 0);
  border-bottom-color: #004c36;
  border-width: 23px;
  margin-left: -23px;
}

.tutorialBoxStage9 {
  right: 150px;
  bottom: 75px;
  z-index: 5;
  width: 730px;
}

.tutorialBoxStage9:after, .tutorialBoxStage8:before {
  left: 100%;
  bottom: 23%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.tutorialBoxStage9:after {
  border-color: rgba(166, 237, 199, 0);
  border-left-color: #a6edc7;
  border-width: 22px;
  margin-top: -22px;
}
.tutorialBoxStage9:before {
  border-color: rgba(0, 76, 54, 0);
  border-left-color: #004c36;
  border-width: 23px;
  margin-top: -23px;
}

.noOverflow {
  overflow: hidden;
}